import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { ExternalLink } from "~/atoms/ExternalLink";
import { siteWidth } from "~/utils/variables";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  isTopPage?: Boolean;
};

export const Footer: React.FC<Props> = ({ isTopPage = false }) => {
  const { logo } = useStaticQuery<GatsbyTypes.FooterLogoQuery>(graphql`
    query FooterLogo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${(isTopPage && colors.lightGray) || colors.white};
          color: ${colors.defaultText};
          padding: 60px 30px 30px;
        }
        .container {
          display: flex;
          margin: 0 auto;
          max-width: ${siteWidth.default};
        }
        .logo {
          display: block;
          height: 32px;
          width: 120px;
        }
        .navigationsPc {
          display: flex;
          margin-left: auto;
        }
        .navigationsSp {
          display: none;
        }
        .navigation + .navigation {
          margin-left: 30px;
        }
        .navigationTitle {
          font-size: 13px;
          font-weight: 700;
        }
        .navigationList {
          margin-top: 10px;
          list-style: none;
        }
        .navigationListItem {
          font-size: 13px;
          margin-top: 5px;
        }
        .navigationListItem :global(a),
        .navigationListItem :global(a:visited),
        .navigationListItem :global(a:active) {
          color: ${colors.defaultText};
        }
        .navigationListItem :global(a:hover) {
          text-decoration: underline;
        }
        .navigationListItem :global(a .en) {
          font-size: 1.16em;
        }
        .copyright {
          display: flex;
          justify-content: center;
          margin-top: 50px;
        }
        .copyrightText {
          font-size: 11px;
          text-align: center;
          line-height: 1;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 40px 20px 20px;
          }
          .container {
            flex-direction: column;
          }
          .navigationsPc {
            display: none;
          }
          .navigationsSp {
            display: flex;
            justify-content: flex-start;
            margin-top: 30px;
          }
          .navigationListSp {
            list-style: none;
          }
          .navigationListSp + .navigationListSp {
            margin-left: 30px;
          }
          .navigationListItem {
            font-size: 14px;
          }
        }
      `}</style>
      <footer className="wrapper">
        <div className="container">
          <div className="logo">
            {logo?.childImageSharp && (
              <Link to="/">
                <GatsbyImage image={logo?.childImageSharp?.gatsbyImageData} alt="refcome" />
              </Link>
            )}
          </div>
          <div className="navigationsPc">
            <nav className="navigation">
              <h2 className="navigationTitle">
                <span className="en">Refcome</span>
              </h2>
              <ul className="navigationList">
                <li className="navigationListItem">
                  <Link to="/cases">導入事例</Link>
                </li>
              </ul>
            </nav>
            <nav className="navigation">
              <h2 className="navigationTitle">イベント</h2>
              <ul className="navigationList">
                <li className="navigationListItem">
                  <Link to="/events">セミナー開催情報</Link>
                </li>
                <li className="navigationListItem">
                  <Link to="/events/report">セミナーレポート</Link>
                </li>
                <li className="navigationListItem">
                  <Link to="/events/award">アワード</Link>
                </li>
              </ul>
            </nav>
            <nav className="navigation">
              <h2 className="navigationTitle">メディア</h2>
              <ul className="navigationList">
                <li className="navigationListItem">
                  <Link to="/insight">
                    <span className="en">Insight</span>
                  </Link>
                </li>
              </ul>
            </nav>
            <nav className="navigation">
              <h2 className="navigationTitle">規約</h2>
              <ul className="navigationList">
                <li className="navigationListItem">
                  <Link to="https://refcome.team/terms">利用規約</Link>
                </li>
                <li className="navigationListItem">
                  <Link to="/privacy">個人情報の取り扱いについて</Link>
                </li>
                <li className="navigationListItem">
                  <Link to="/privacy/statement">プライバシーポリシー</Link>
                </li>
              </ul>
            </nav>
            <nav className="navigation">
              <h2 className="navigationTitle">お問い合わせ</h2>
              <ul className="navigationList">
                <li className="navigationListItem">
                  <ExternalLink to="https://about.refcome.com/">会社概要</ExternalLink>
                </li>
                <li className="navigationListItem">
                  <Link to="/inquiry/contact">サービスについて</Link>
                </li>
                <li className="navigationListItem">
                  <ExternalLink to="https://about.refcome.com/contact">その他のお問い合わせ</ExternalLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="navigationsSp">
            <ul className="navigationListSp">
              <li className="navigationListItem">
                <Link to="/cases">導入事例</Link>
              </li>
              <li className="navigationListItem">
                <Link to="/events">セミナー開催情報</Link>
              </li>
              <li className="navigationListItem">
                <Link to="/events/report">セミナーレポート</Link>
              </li>
              <li className="navigationListItem">
                <Link to="/events/award">アワード</Link>
              </li>
              <li className="navigationListItem">
                <Link to="/insight">
                  <span className="en">Insight</span>
                </Link>
              </li>
            </ul>
            <ul className="navigationListSp">
              <li className="navigationListItem">
                <ExternalLink to="https://about.refcome.com/">会社概要</ExternalLink>
              </li>
              <li className="navigationListItem">
                <Link to="/inquiry/contact">サービスについて</Link>
              </li>
              <li className="navigationListItem">
                <ExternalLink to="https://about.refcome.com/contact">その他のお問い合わせ</ExternalLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <small className="copyrightText">Copyright © Refcome, Inc. All rights reserved.</small>
        </div>
      </footer>
    </>
  );
};
