const DEFAULT_SITE_WIDTH = 1000;

export const siteWidth = {
  default: `${DEFAULT_SITE_WIDTH}px`,
};

export const breakPoints = {
  sp: `max-width: 767px`,
  minPc: `min-width: 768px`,
  tablet: `max-width: ${DEFAULT_SITE_WIDTH - 1}px`,
  pc: `min-width: ${DEFAULT_SITE_WIDTH}px`,
};

export const fontFamily = {
  default: `Metric, "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif`,
  insight: `"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif`,
};

export const approvalAuthorities = [`最終承認者`, `意思決定権者`, `稟議起案者`, `その他`];
export const roles = [`役員以上`, `部長級`, `課長級`, `一般社員`];
export const positions = [`採用に携わっている`, `採用には携わっていない`];
export const prefectures = [
  `北海道`,
  `青森県`,
  `岩手県`,
  `宮城県`,
  `秋田県`,
  `山形県`,
  `福島県`,
  `茨城県`,
  `栃木県`,
  `群馬県`,
  `埼玉県`,
  `千葉県`,
  `東京都`,
  `神奈川県`,
  `新潟県`,
  `山梨県`,
  `長野県`,
  `富山県`,
  `石川県`,
  `福井県`,
  `岐阜県`,
  `静岡県`,
  `愛知県`,
  `三重県`,
  `滋賀県`,
  `京都府`,
  `大阪府`,
  `兵庫県`,
  `奈良県`,
  `和歌山県`,
  `鳥取県`,
  `島根県`,
  `岡山県`,
  `広島県`,
  `山口県`,
  `徳島県`,
  `香川県`,
  `愛媛県`,
  `高知県`,
  `福岡県`,
  `佐賀県`,
  `長崎県`,
  `熊本県`,
  `大分県`,
  `宮崎県`,
  `鹿児島県`,
  `沖縄県`,
];
export const numberOfEmployees = [
  `1〜30人`,
  `30〜70人`,
  `70〜150人`,
  `150〜300人`,
  `300〜500人`,
  `500〜1000人`,
  `1000〜3000人`,
  `3000人〜`,
];
export const referralPolicies = [
  `リファラル注力中`,
  `リファラルを最近始めたばかり（3カ月以内）`,
  `紹介制度のみ存在`,
  `リファラル未実施`,
];
export const referralTargets = [`中途`, `新卒`, `アルバイト`, `派遣`, `社員登用`, `アルムナイ`, `自薦・他薦`, `その他`];
export const referralProblems = [
  `周知をしているがうまくいっていない`,
  `データに基づく改善施策を組めない`,
  `施策の展開や管理に工数がかかっている`,
  `成功イメージが持てない`,
];
export const referralTimelines = [`すぐに`, `1ヶ月以内`, `3ヶ月以内`, `6ヶ月以内`, `情報収集`];
export const referralBudgets = [`既に付けている`, `費用対効果が合えば付けられる`, `付ける予定はない`];
export const numbersOfEmployees = ["100名以下", "101～300名", "301～1,000名", "1,001～5,000名", "5,001名以上"];
export const numbersOfHiredAnnually = ["30名以上", "30名未満"];
export const ratiosOfRecruitmentAgency = ["人材紹介がメイン", "それ以外がメイン"];
export const preferredRecruitmentTypes = ["中途採用", "新卒採用", "アルバイト・派遣採用"];
export const currentStatuses = ["今すぐ強化したい", "3ヶ月以内に強化したい", "情報収集中"];
