export const REFCOME_LOGIN_URL = `https://refcome.com/managers/sign_in`;
export const PRIVACY_POLICY_URL = `https://jp.refcome.com/privacy/statement`;
export const PRIVACY_POLICY_MARKETING_URL = `https://jp.refcome.com/privacy`;
export const PATH = {
  TOP: {
    name: `トップ`,
    to: `/`,
  },
  INSIGHT: {
    name: `Insight`,
    to: `/insight`,
  },
  CASES: {
    name: `導入事例`,
    to: `/cases`,
  },
  AWARD: {
    name: `AWARD`,
    to: `/awards`,
  },
  AWARD_2020: {
    name: `第３回受賞企業インタビュー`,
    to: `/awards/2020`,
  },
  AWARD_2023: {
    name: `第4回受賞企業インタビュー`,
    to: `/awards/2023`,
  },
  EVENTS: {
    name: `イベント`,
    to: `/events`,
  },
  EVENTS_SEMINAR: {
    name: `セミナー情報`,
    to: `/events/seminar`,
  },
  EVENTS_REPORT: {
    name: `イベントレポート`,
    to: `/events/report`,
  },
  EVENTS_AWARD: {
    name: `Referral Recruiting AWARD`,
    to: `/events/award`,
  },
  EVENTS_AWARD_2018: {
    name: `第２回受賞企業インタビュー`,
    to: `/events/award/interview2018`,
  },
  EVENTS_AWARD_2017: {
    name: `第１回受賞企業インタビュー`,
    to: `/events/award/interview2017`,
  },
  DOWNLOADS: {
    name: `ダウンロード`,
    to: `/downloads`,
  },
  INQUIRY_CONTACT: {
    name: `サービスについての資料請求・お問い合わせ`,
    to: `/inquiry/contact`,
  },
};
