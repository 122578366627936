// TODO: gray toneを整理する
const Pallet = {
  white: `#fff`,
  black: `#41423C`,
  gray: `#f5f5f5`,
  gray2: `#999`,
  gray3: `#efefef`,
  lightGray: `#f9f8f7`,
  creamGray: `#f5f5f0`,
  creamGray2: `#e9e9e4`,
  littleBrightGray: `#969696`,
  orange: `#ff6400`,
  lightOrange: `#fff0eB`,
  purple: `#86a1f3`,
  lightPurple: `#c3dfdd`,
  red: `#ff6673`,
  lightRed: `#f5c6c3`,
  lightBlue: `#76C8C8`,
};

const Semantics = {
  defaultText: Pallet.black,
  lightText: Pallet.gray2,
  defaultBg: Pallet.gray,
  brand: Pallet.orange,
  brandAccent: Pallet.purple,
  brandGradient: `linear-gradient(270deg, #EA3375 -73%, #EA3572 -69.4%, #EB4656 -31.72%, #EC5145 1.32%, #EC3F50 25.96%, #EC8317 100%)`,
  bgGrayGradient: `linear-gradient(180deg, ${Pallet.white} 10%, ${Pallet.creamGray} 60%);`,
  error: Pallet.red,
};

export const colors = {
  ...Pallet,
  ...Semantics,
};
